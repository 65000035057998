import React from "react"

export default ({ className = "", children, id = "" }) => {
  return (
    <div className="flex justify-center items-center mt-4 w-full  " id={id}>
      <hr className="ml-4 mr-4 md:ml-32 md:mr-4 flex-grow border-t-4 hidden md:inline-block" />
      <h1
        className={`font-heading  leading-none text-4xl text-center text-blue-light ${className}`}
      >
        {children}
      </h1>
      <hr className="ml-4 mr-4 md:ml-4 md:mr-32 flex-grow border-t-4 hidden md:inline-block" />
    </div>
  )
}
