import React from "react"
import BackgroundImage2 from "../components/BackgroundImage"
import Syllabics from "./Syllabics"

// If you need to use the z-index for some reason (current use case was the overlapping homepage boxes).
// You have to pass zIndex={value} down from the hero, which will also pass it down to the BackgroundImage component

const HeroHeading = ({ children }) => (
  <h1 className="font-normal text-3xl md:text-4xl xl:text-6xl text-center m-0 text-white textShadow">
    {children}
  </h1>
)

const Hero = ({
  englishTitle = null,
  syllabics = null,
  heroImage = null,
  centered = false,
  zIndex = null,
  children,
  bgClassName,
}) => {
  return (
    <div className="flex items-center  justify-center overflow-hidden ">
      <BackgroundImage2
        zIndex={zIndex}
        heroImage={heroImage}
        className={bgClassName}
      >
        <div className={`grid items-end md:min-h-12rem my-5 px-2 md:px-5`}>
          <div className="">
            {syllabics && (
              <HeroHeading>
                <span>{syllabics}</span>
              </HeroHeading>
            )}
            {englishTitle && <HeroHeading>{englishTitle}</HeroHeading>}
          </div>
          {children}
        </div>
      </BackgroundImage2>
    </div>
  )
}

export default Hero
