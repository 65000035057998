import React from "react"
import { graphql, Link } from "gatsby"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import NewsletterSignUp from "../components/NewsletterSignup"
import BlockContentPrimary from "../components/blockContentPrimary"

const About = ({ data }) => {
  const {
    englishTitle,
    syllabicsTitle,
    aboutUsHeadline,
    aboutUsSection,
    heroImage,

    _rawMainContent: rawMainContent,
  } = data.sanityAboutUs
  const communityInfo = data.communityInfo.edges
  const hero = heroImage ? heroImage.asset : null

  return (
    <Layout>
      <SEO title="About Us" />
      <Hero
        englishTitle={englishTitle}
        syllabics={syllabicsTitle}
        heroImage={hero}
        bgClassName="bg-top"
      />
      {/* <section className="flex flex-col justify-center md:-mt-12 mt-0 mb-12 bg-gray-dark max-w-4xl mx-auto">
        <h3 className="font-heading text-center mt-4 mb-4 text-4xl">
          {aboutUsHeadline ? aboutUsHeadline : "About Us"}
        </h3>
        <p className="text-justify text-xl w-full px-5 sm:px-16 py-4  leading-snug">
          {aboutUsSection}
        </p>
      </section> */}
      <section className="flex mx-4 xl:mx-6  leading-tight text-blue-dark  justify-self-center my-10">
        <div className="text-blue-dark text-md  ">
          <BlockContentPrimary blockData={rawMainContent} />
        </div>
      </section>
      {/* <NewsletterSignUp>Get In The Loop</NewsletterSignUp> */}
    </Layout>
  )
}

export default About

export const query = graphql`
  query AboutUs {
    sanityAboutUs {
      englishTitle
      syllabicsTitle
      _id
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawMainContent(resolveReferences: { maxDepth: 10 })
    }
    communityInfo: allSanityIndividualCommunityPages(
      sort: { fields: communityName, order: ASC }
    ) {
      edges {
        node {
          communityName
          slug {
            current
          }
          communityLogo {
            asset {
              assetId
              fluid {
                ...GatsbySanityImageFluid
              }
            }
            altText
          }
          id
        }
      }
    }
  }
`
